@tailwind base;
@tailwind components;
@tailwind utilities;

.no-scrollbar::-webkit-scrollbar {
    display: none !important;
}

.no-scrollbar {
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
}

.ck-content > blockquote,
.ck-content > dl,
.ck-content > dd,
.ck-content > h1,
.ck-content > h2,
.ck-content > h3,
.ck-content > h4,
.ck-content > h5,
.ck-content > h6,
.ck-content > hr,
.ck-content > figure,
.ck-content > p,
.ck-content > pre {
    margin: revert;
}

.ck-content > ol,
.ck-content > ul {
    list-style: revert;
    margin: revert;
    padding: revert;
}

.ck-content > table {
    border-collapse: revert;
}

.ck-content > h1,
.ck-content > h2,
.ck-content > h3,
.ck-content > h4,
.ck-content > h5,
.ck-content > h6 {
    font-size: revert;
    font-weight: revert;
}
